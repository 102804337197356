@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css"; 
@import "slick-carousel/slick/slick-theme.css";
.slick-prev:before, .slick-next:before { 
  color:rgb(63, 63, 63) !important;
}
.swatch-wrapper {
  text-align: center;
}
.color-swatch {
  width: 26px;
  height: 26px;
  margin: 6px;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
}